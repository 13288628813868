.container {
	position: relative;
}

.next_button, .prev_button {
	position: absolute;
	transform: translateY(-50%);
	top: 50%;

	background: none;
	border: 0;
}

.next_button {
	right: 5px;
}

.prev_button {
	left: 5px;
}

.carousel {
  	overflow: hidden;
}
.carousel__container {
  	display: flex;
}
.carousel__item {
  	position: relative;
  	flex: 0 0 470px;
  	max-width: 100%;
  	margin-right: 10px;
  	height: 190px;
  	background-position: center center;
  	background-repeat: no-repeat;
  	background-size: cover;
  	border-radius: 1rem;

  	img {
  		min-height: 100%;
		min-width: 100%;
		/* IE 8 */
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		/* IE 5-7 */
		filter: alpha(opacity=0);
		/* modern browsers */
		opacity: 0;
  	}
}