@import "_variables";
@import "_mixins";

.productNav {
	overflow-x: auto;
	width: 100%;
	white-space: nowrap;
	padding-bottom: 10px;
	scrollbar-width: none;
}

.productNav::-webkit-scrollbar {
    display: none;
}

.productNav_line {
	height: 8px;
	background-color: #ebebeb;
	width: 100%;
	margin-top: -18px;
	margin-bottom: 1rem;
}

.productNav_item {
	display: inline-block;
	margin-right: 2rem;

	button {
		@include font-semibold;
		font-size: 1.1rem;
		background: none;
		border: none;
		display: inline-block;
	}
}

.productNav_itemActive {
	button {
		@include font-bold;
		margin-bottom: 10px;
	}
}

.productNav_activeHighlight {
	background-color: $primary;
	height: 8px;
	border-radius: 4px;
}