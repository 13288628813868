@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';
@import '~bootstrap/scss/mixins/_grid.scss';

.bannersSection {
	padding-bottom: 5rem;
	z-index: 1;
	position: relative;
}

.searchSection {
	margin-bottom: 2rem;

	&.bannerShown {
		position: relative;
		z-index: 2;
		width: 100%;
	}
}

.searchSection_wrapper {
	border-radius: 1.5rem;
}

$search-form-radius: 2rem;
.searchSection_formControl {
	padding: 1.5rem 1.75rem !important;
	border-top-left-radius: $search-form-radius !important;
	border-bottom-left-radius: $search-form-radius !important;
	border-width: .25rem 0 .25rem .25rem !important;
}

.searchSection_formButton {
	padding: .25rem 1.5rem !important;
	border-radius: 0 $search-form-radius $search-form-radius 0 !important;
}

.marketplaceSection {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 1.25rem;
}

.marketplace_icon {
	width: 60px;
	margin-right: 1rem;
}

.searchAndMarketplaceContainer {
	position: relative;
	top: 0;
	padding-top: 2rem;

	&.bannerShown {
		top: -3.75rem;
		padding-top: 0;
	}
}

.productGroups {
	margin-top: -0.75rem;
}

.emailVerifiedModal > div > div {
	border-radius: 1rem;
}

.paymentMethodSection {
	img {
		height: 40px;
		margin-bottom: 10px;

		@include media-breakpoint-up(md) { 
			height: 50px;
			margin-bottom: 0;
		}
	}
}