@import "_variables";
@import "_mixins";

$banner-radius: 1rem;

.header {
	@include font-extrabold;

	border-radius: $banner-radius;
	height: 80px;
	color: white;
	text-align: right;

	h4 {
		line-height: 80px;
		text-shadow: 2px 2px #000000;
	}
}

.headerBackground {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: $banner-radius;
	padding-right: 2rem;
}

.noProductPlacehold {
	height: 300px;
	text-align: center;
}