@import "_mixins";
@import "_variables";


$productFontSize: 14px;

.filters {
	font-size: $productFontSize;
	h4 {
		font-size: $productFontSize;
		@include font-bold;
	}
}

.filters_header {
	cursor: pointer;
}

.filterColorPreview {
	display: inline-block;
	margin-right: 0.5rem;
	width: 10px;
	height: 10px;
}

.filters_parentCategory {
	@include font-extrabold;
	color: $primary;
	margin-bottom: 0.5rem;
}

.filters_category {
	@include font-medium;
	display: block;
	margin-bottom: 0.5rem;
	color: #333;
	margin-left: 1rem;

	&:hover {
		color: darken(#333, 15%);
		text-decoration: none;
	}
}

.productCard {
	font-size: $productFontSize;
	border-radius: 1rem !important;
	border-color: transparent !important;
	border-width: .15rem !important;
	max-width: 100%;
	overflow: hidden;

	.productImageContainer {
		width: 100%;
		height: 196px;
		display: flex;
		align-items: center;
	}

	.productImage {
		max-width: 100%;
		vertical-align: middle;
	}

	.productName {
		color: #333;
		line-height: 16px;
		min-height: 32px;
	}

	.productMarketplace {
		color: #838383;
		font-size: 0.7rem;
		text-transform: uppercase;
		@include font-regular;
	}

	.productPrice {
		@include font-extrabold;
		line-height: 20px;
		min-height: 40px;
	}

	&:hover {
		border-color: $primary !important;
		box-shadow: 0 .5rem 1rem rgba(#000, .15);
	}
}

.productLink {
	button {
		visibility: hidden;
		border-radius: 0 0 .75rem .75rem;
	}

	&:hover {
		text-decoration: none;

		button {
			visibility: visible;
		}
	}
}