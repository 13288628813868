.container {
	position: relative;
}

.next_button, .prev_button {
	position: absolute;
	transform: translateY(-50%);
	top: 50%;

	background: none;
	border: 0;
}

.next_button {
	right: 5px;
}

.prev_button {
	left: 5px;
}

.carousel {
  	overflow: hidden;
}
.carousel__container {
  	display: flex;
}
.carousel__item {
  	position: relative;
  	flex: 0 0 50%;
	padding-left: 4px;
	padding-right: 4px;
	margin-bottom: .5rem;

  	@media (min-width: 576px) {
	  	flex-basis: 33.33%;
	}

	@media (min-width: 768px) {
	  	flex-basis: 25%;
	}

	@media (min-width: 992px) {
		flex-basis: 20%;
	}
}

