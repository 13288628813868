@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  position: relative;
  flex: 0 0 50%;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: .5rem;

  @include media-breakpoint-up(md) {
    flex-basis: 25%;
  }

  @include media-breakpoint-up(lg) {
    flex-basis: 20%;
  }
}
